import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faSearch, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';

function ContactUsDetails() {
    const [details, setDetails] = useState([]);

    useEffect(() => {
        fetch('http://localhost/waltzify_copy/Backend/Database/fetch_contactus_details.php')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setDetails(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setDetails([]); // Handle error state
            });
    }, []);

    const handleExport = () => {
        const worksheet = XLSX.utils.json_to_sheet(details);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'ContactDetails');
        XLSX.writeFile(workbook, 'contactus_list.xlsx');
    };

    return (
        <div className='bg-[#F2F6F9] py-[2rem]'>
            <div className='flex flex-col lg:flex-row justify-between lg:items-center px-[2rem] lg:px-[4rem]'>
                <p className='text-xl lg:text-3xl font-bold'>All Users</p>
                <p className='text-gray-600'>
                    Dashboard <FontAwesomeIcon icon={faArrowRight} /> Users <FontAwesomeIcon icon={faArrowRight} /> <span className='font-light text-gray-500'>User List</span>
                </p>
            </div>
            <div className='bg-white rounded-xl my-[2rem] mx-[2rem] lg:mx-[4rem] py-[2rem]'>
                <div className='px-[2rem] py-[1rem] flex justify-end'>
                    <button onClick={handleExport} className='text-[#3B81F6] hover:text-white hover:bg-[#3B81F6] border-2 border-[#3B81F6] py-2 px-4 rounded-xl'>
                        <FontAwesomeIcon className='pr-[1rem]' icon={faFileAlt} />
                        Export All Users
                    </button>
                </div>
                {/* Users List */}
                <div className='overflow-x-auto lg:mx-[3rem]'>
                    <div className='w-full'>
                        {/* Headers */}
                        <div className='bg-[#F2F6F9] mx-[2rem] lg:mx-[3rem] rounded-xl'>
                            <div className='flex justify-between items-center p-[1rem]'>
                                <p className='font-bold w-[15%]'>First Name</p>
                                <p className='font-bold w-[15%]'>Last Name</p>
                                <p className='font-bold w-[20%]'>Email</p>
                                <p className='font-bold w-[15%]'>Phone</p>
                                <p className='font-bold w-[15%]'>Subject</p>
                                <p className='font-bold w-[20%]'>Message</p>
                            </div>
                        </div>
                        {/* User data */}
                        {details.map(user => (
                            <div key={user.Id} className='py-[0.5rem] flex items-center justify-between gap-[1rem] mx-[2rem] lg:mx-[3rem] border-b'>
                                <p className='w-[15%] text-sm'>{user.first_name}</p>
                                <p className='w-[15%] text-sm'>{user.last_name}</p>
                                <p className='w-[20%] text-sm'>{user.email}</p>
                                <p className='w-[15%] text-sm'>{user.phone}</p>
                                <p className='w-[15%] text-sm'>{user.subject}</p>
                                <p className='w-[20%] text-sm'>{user.message}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <hr className='mx-[2rem]' />
                <div className='mx-[5rem] mt-[1rem]'>
                    <p className='font-thin text-sm'>Showing {details.length} entries</p>
                </div> 
            </div>
        </div>
    );
}

export default ContactUsDetails;





/* import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faSearch, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';

function ContactUsDetails() {
    const [details, setDetails] = useState([]);
   
    

    useEffect(() => {
        fetch('http://localhost/waltzify_copy/Backend/Database/fetch_contactus_details.php')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setDetails(data);
              
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setDetails([]); // Handle error state
            });
    }, []);

   

    const handleExport = () => {
        const worksheet = XLSX.utils.json_to_sheet(details);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'ContactDetails');
        XLSX.writeFile(workbook, 'contactus_list.xlsx');
    };

    return (
        <div className='bg-[#F2F6F9] py-[2rem]'>
            <div className='flex flex-col lg:flex-row justify-between lg:items-center px-[2rem] lg:px-[4rem]'>
                <p className='text-xl lg:text-3xl font-bold'>All Users</p>
                <p className='text-gray-600'>Dashboard <FontAwesomeIcon icon={faArrowRight} /> Users <FontAwesomeIcon icon={faArrowRight} /> <span className='font-light text-gray-500'>User List</span></p>
            </div>
            <div className='bg-white rounded-xl my-[2rem] mx-[2rem] lg:mx-[4rem] py-[2rem]'>
            <div className='px-[2rem] py-[1rem] flex flex-col lg:flex-row gap-[2rem] justify-between lg:items-center'>
                    <button onClick={handleExport} className='text-[#3B81F6] hover:text-white hover:bg-[#3B81F6] border-2 border-[#3B81F6] py-2 px-[2rem] rounded-xl'>
                        <FontAwesomeIcon className='pr-[1rem]' icon={faFileAlt}/>
                        Export All Users
                    </button>
                </div>
                
                <div className='overflow-scroll lg:mx-[3rem]'>
                    <div className='w-[80rem]'>
                        
                        <div className='bg-[#F2F6F9] mx-[2rem] lg:mx-[3rem] rounded-xl'>
                            <div className='flex justify-evenly gap-[20rem] mx-[1rem] items-center p-[1rem]'>
                                <p className='font-bold mr-[6rem]'>First Name</p>
                                <p className='font-bold mr-[6rem]'>Last Name</p>
                                <p className='font-bold'>Email</p>
                                <p className='font-bold'>Phone</p>
                                <p className='font-bold'>Subject</p>
                                <p className='font-bold'>Message</p>

                            </div>
                        </div>
                        
                        {details.map(user => (
                            <div key={user.Id} className='py-[0.5rem] flex items-center justify-evenly gap-[16rem] mx-[2rem] lg:mx-[3rem]'>
                                <div className='flex items-center gap-[1rem] justify-evenly mx-[3rem]'>
                                    <div className='ml-5'>
                                        <p className='w-[15rem] font-bold text-sm'>{user.first_name}</p>
                                    </div>
                                </div>
                                <p className='w-[10rem] text-sm'>{user.last_name}</p>
                                <p className='w-[15rem] text-sm'>{user.email}</p>
                                <p className='w-[15rem] text-sm'>{user.phone}</p>
                                <p className='w-[15rem] text-sm'>{user.subject}</p>
                                <p className='w-[15rem] text-sm'>{user.message}</p>

                            </div>
                        ))}
                    </div>
                </div>
                <hr className='mx-[2rem]' />
                <div className='mx-[5rem] mt-[1rem]'>
                    <p className='font-thin text-sm'>Showing {displayedUsers.length} entries</p>
                </div> 
            </div>
        </div>
    );
}

export default ContactUsDetails; */
