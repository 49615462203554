import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Collections() {
  const [categories, setCategories] = useState([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    fetch('http://localhost/waltzify_copy/Backend/Database/Fetch_Collection.php')
      .then(response => response.json())
      .then(data => setCategories(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const displayedCategories = showAll ? categories : categories.slice(0, 12);

  return (
    <div className='py-[3rem]'>
      {/* heading */}
      <div className='flex flex-col gap-3 justify-center items-center'>
        <h1 className='text-3xl font-bold drop-shadow-xl'>COLLECTIONS</h1>
        <p>BEST OF WALTZER</p>
      </div>
      {/* collections */}
      <div className='py-[3rem] px-[1rem] lg:px-[4rem] grid grid-cols-2 lg:grid-cols-6 justify-between flex-wrap gap-[2rem] lg:gap-[3rem] items-center overflow-hidden'>
        {displayedCategories.map((category, index) => (
          <div key={index} className='group flex flex-col items-center justify-center gap-2'>
            <Link to={'/allproduct'} >
              <img className='group-hover:border-orange-500 border-4 lg:w-[10rem] w-[7rem] h-[7rem] lg:h-[10rem] rounded-full' src={`http://localhost/waltzify_copy/Backend/Database/Collection/${category.images1}`} alt={category.cname} />
              <p className='w-[8rem] text-sm font-bold py-1 px-2 rounded-lg group-hover:bg-orange-500 group-hover:text-white transition-all ease-in-out delay-100 text-center lg:ml-[1rem]'>{category.collectionName}</p>
            </Link>
          </div>
        ))}
      </div>
      {/* VIEW ALL button */}
      {categories.length > 12 && !showAll && (
        <div className='flex justify-center mt-6'>
          <button
            onClick={() => setShowAll(true)}
            className='bg-orange-500 text-white py-2 px-4 rounded-lg hover:bg-orange-600 transition-all'
          >
            VIEW ALL
          </button>
        </div>
      )}
    </div>
  );
}

export default Collections;











