import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';


function Hero() {
  
  const [products, setProducts] = useState([]);
  const [banners, setBanners] = useState([]);
  const [defaultbanner,setDefaultBanner] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const visibleCount = 5;

  useEffect(() => {
    fetch('http://localhost/waltzify_copy/Backend/Database/Fetch_Category.php')
      .then(response => response.json())
      .then(data => setProducts(data))
      .catch(error => console.error('Error fetching products:', error));
  }, []);

  useEffect(() => {
    fetch('http://localhost/waltzify_copy/Backend/Database/fetch_banner.php')
      .then(response => response.json())
      .then(data => setBanners(data))
      .catch(error => console.error('Error fetching banners:', error));
  }, []);

  /* useEffect(() => {
    fetch('http://localhost/waltzify_copy/Backend/Database/fetch_default_banner.php')
      .then(response => response.json())
      .then(data => setDefaultBanner(data))
      .catch(error => console.error('Error fetching banners:', error));
      
  }, []); */
  useEffect(() => {
    fetch('http://localhost/waltzify_copy/Backend/Database/fetch_default_banner.php')
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setDefaultBanner(data);
        } else {
          console.error('Error: Expected an array but received:', data);
          setDefaultBanner([]);
        }
      })
      .catch(error => {
        console.error('Error fetching default banners:', error);
        setDefaultBanner([]); // Default to an empty array if there's an error
      });
  }, []);
  
  

  const prevImage = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, products.length - visibleCount));
  };

  return (
    <div className="relative h-[23rem] lg:h-[59rem] overflow-hidden">
      
      <div>
  {banners.length > 0 ? (
    banners.map((banner, index) => (
      <div key={banner.id || index} className="relative w-full h-[16rem] lg:h-[40rem]">
        <img className="w-full h-full" src={`http://localhost/waltzify_copy/Backend/Database/Banner/${banner.image}`} alt={banner.text} />
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white">
          <a href={'/allproduct'} className="bg-black bg-opacity-50 p-4 rounded">
            <h2 className="text-xl lg:text-3xl font-bold">{banner.text}</h2>
          </a>
        </div>
      </div>
    ))
  ) : (
    defaultbanner
      .filter((defaultbanner) => defaultbanner.location === "Home Page")
      .map((defaultbanner, index) => (
        <div key={defaultbanner.id || index} className="relative w-full h-[16rem] lg:h-[40rem]">
          <img
            className="w-full h-full"
            src={`http://localhost/waltzify_copy/Backend/Database/DefaultBanner/${defaultbanner.image}`}
            alt="default banner"
          />
        </div>
      ))
  )}
</div> 
    


      
      <button onClick={prevImage} className="z-[100] lg:text-3xl absolute top-[16rem] -left-4 lg:left-0 lg:top-[29rem] ml-4 p-2 rounded-full bg-white">
        <FaChevronLeft />
      </button>
      <div className="absolute top-[12rem] lg:top-[28rem] px-[1rem] flex justify-evenly items-center gap-[2rem] transition-transform duration-500 ease-in-out" style={{ transform: `translateX(-${currentIndex * (100 / visibleCount)}%)` }}>
        {products.map((product, index) => (
          <div key={index} className="hover:scale-[1.02] transition-all ease-in-out duration-[500ms] rounded-xl group relative w-[7rem] lg:w-[20rem] overflow-hidden bg-white">
            <Link to={`/category/${product.cname}`} >
              <div>
                <img className="group-hover:scale-105 shadow-2xl transition-all ease-in-out duration-[500ms] w-full lg:h-[30rem] h-[10rem]" src={`http://localhost/waltzify_copy/Backend/Database/Category/${product.image}`} alt="" />
                <p className="transition-all ease-in-out absolute top-[14rem] w-[20rem] left-[-5rem] text-7xl text-white font-bold -rotate-90">{product.cname}</p>
              </div>
            </Link>
          </div>
        ))}
        </div> 
        

      
      {currentIndex < products.length - visibleCount && (
        <button onClick={nextImage} className="z-[100] lg:text-3xl absolute top-[16rem] lg:top-[29rem] -right-4 lg:right-0 mr-4 p-2 rounded-full bg-white">
          <FaChevronRight />
        </button>
      )}
    </div>
  );
}

export default Hero;
 