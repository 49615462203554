import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function ResetAdminPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (msg) {
      const timer = setTimeout(() => setMsg(""), 5000);
      return () => clearTimeout(timer);
    }
  }, [msg]);
  const isPasswordValid = (password) => {
    const minLength = 8;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const digitRegex = /\d/;
    const uppercaseRegex = /[A-Z]/;
    
    if (password.length < minLength) {
      return 'Password must be at least 8 characters long.';
    }
    if (!specialCharRegex.test(password)) {
      return 'Password must contain at least one special character.';
    }
    if (!digitRegex.test(password)) {
      return 'Password must contain at least one digit.';
    }
    if (!uppercaseRegex.test(password)) {
      return 'Password must contain at least one uppercase letter.';
    }
    return '';
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const passwordError = isPasswordValid(password);
    if (passwordError) {
      setError(passwordError);
      return;
    }
    if (password.trim() === '') {
        setError('Password field are required!');
        return;
    }
   

    const url = 'http://localhost/waltzify_copy/Backend/Database/update_adminPwd.php';
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
    const data = { email, password };

    fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
        credentials: 'include',
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((response) => {
            if (response.result === 'Not Submitted, Please try again!') {
                setError(response.result);
            } else {
                setMsg("Password updated successfully!");
                setTimeout(() => {
                    navigate('/AdminLogin');
                }, 1000);
            }
        })
        .catch((err) => {
            setError('An error occurred. Please try again later.');
            console.error('Error fetching data:', err);
        });
  };

  const handleInputChange = (e, type) => {
    setError("");
    if (type === "password") {
      setPassword(e.target.value);
    
    }
  };

  return (
    <div className='flex flex-col items-center gap-[2rem] mt-[6rem]'>
      <div className='text-center pb-[3rem]'>
        <h3 className='text-4xl'>Reset Password!</h3><br></br>
        <p className='text-2xl'>Enter your new password</p>
      </div>
      <div className='flex flex-col md:w-1/4 gap-6 border-[1px] p-[3rem] rounded-2xl border-black'>
        <p>
          {msg ? <span className="success">{msg}</span> : <span className="error">{error}</span>}
        </p>
        <form className='flex flex-col gap-[1rem]' onSubmit={handleSubmit}>
          <input className='border-[1px] border-black p-2 rounded-3xl outline-none' type="password" value={password} onChange={(e) => handleInputChange(e, "password")} placeholder='Enter New Password' />
          <button type="submit" className='bg-black text-white p-2 rounded-3xl font-bold'>Reset Password</button>
        </form>
        <Link to={'/AdminLogin'}>
          <p className='cursor-pointer font-bold'>Want to Login?</p>
        </Link>
      </div>
      <div>
        <p className='cursor-default'>Don't have an account? <Link to={'/AdminSign'}><span className='text-[#00843C] font-bold cursor-pointer'>Sign up</span></Link></p>
      </div>
    </div>
  )
}

export default ResetAdminPassword;

